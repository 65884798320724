import Q from 'q';
import moment from 'moment';
// 详情
import trains_buyerOrder_getBuyerOrderDetail from '@/lib/data-service/trains/trains_buyerOrder_getBuyerOrderDetail.js';
// // 获取订单支付状态
import trains_order_orderPaymentStatus from '@/lib/data-service/trains/trains_order_orderPaymentStatus.js';
// 取消订单
import trains_order_applyForCancel from '@/lib/data-service/trains/trains_order_applyForCancel.js';

export default {
  components: {},
  data() {
    return {
      loading: false,
      detail: {},
      fightVisble: false,
      insuranceVisble: false,
      insurance: {
        insuranceName: '',
        insuranceNo: '',
        insuranceStatus: '',
        insurancePrice: '',
        insuranceStatusText: '',
      },
    };
  },
  computed: {

  },
  methods: {
    // 详情
    req_detail(params) {
      clearInterval(this.timer);
      this.loading = true;
      const _this = this;
      Q.when()
        .then(function() {
          return trains_buyerOrder_getBuyerOrderDetail(params);
        })
        .then(function(res) {
          res.detailResults.forEach(function(item) {

            if (item[0].passengerType) {
              switch (item[0].passengerType) {
                case 1:
                  item[0].passengerTypeText = '成人';
                  break;
                case 2:
                  item[0].passengerTypeText = '儿童';
                  break;
                case 3:
                  item[0].passengerTypeText = '学生';
                  break;
                case 4:
                  item[0].passengerTypeText = '军残';
                  break;
              }
            }

            if (item[0].passengerCardType) {
              switch (parseInt(item[0].passengerCardType)) {
                case 1:
                  item[0].passengerCardTypeText = '身份证';
                  break;
                case 2:
                  item[0].passengerCardTypeText = '儿童无证件';
                  break;
                case 3:
                  item[0].passengerCardTypeText = '港澳通行证';
                  break;
                case 4:
                  item[0].passengerCardTypeText = '台湾通行证';
                  break;
                case 5:
                  item[0].passengerCardTypeText = '护照护照';
                  break;
              }
            }


            if (item[0].passengerCardNumber) {
              item[0].passengerCardNumberFront = item[0].passengerCardNumber.substr(
                0,
                4
              );
              item[0].passengerCardNumberEnding = item[0].passengerCardNumber.substr(
                -3,
                3
              );
            };

            item.forEach(function (fare) {
              if (fare.gmtStart) {
                const startArr = fare.gmtStart.split(' ');
                const day = moment(fare.gmtStart).day();
                switch (day) {
                  case 1:
                  fare.gmtStartText = '星期一';
                  break;
                  case 2:
                  fare.gmtStartText = '星期二';
                  break;
                  case 3:
                  fare.gmtStartText = '星期三';
                  break;
                  case 4:
                  fare.gmtStartText = '星期四';
                  break;
                  case 5:
                  fare.gmtStartText = '星期五';
                  break;
                  case 6:
                  fare.gmtStartText = '星期六';
                  break;
                  case 0:
                  fare.gmtStartText = '星期日';
                  break;
                };
  
                fare.deparTimeText = startArr[0] + ' ' + fare.gmtStartText + ' ' + startArr[1]
              }
            })
            

          })
          _this.detail = res;
          _this.loading = false;
          _this.timer = setInterval(_this.count_down, 1000);
        })
    },
    // 支付状态
    req_order_status(params) {
      return Q.when().then(function() {
        return trains_order_orderPaymentStatus(params);
      });
    },
    // 取消
    req_cancel_order(params) {
      const _this = this;
      Q.when()
        .then(function() {
          return trains_order_applyForCancel(params);
        })
        .then(function(res) {
          _this.$message.success('已申请取消订单，请稍候刷新查看！');
          _this.$router.back(-1);
        })
        .catch(function(err) {
          _this.$message.error(err.data.msg);
          _this.req_detail({
            orderNo: _this.$route.query.orderNo
          });
        })
    },
    count_down() {
      const detail = this.detail;
      const gmtLastPayment = detail.gmtLastPayment;
      const now = moment();
      const diff = moment(gmtLastPayment).diff(now, 'seconds');

      if (diff > 0) {
        detail.countdownToPayment = Math.floor(diff / 60) + '分' + diff % 60 + '秒';
      } else if (diff <= 0) {
        detail.countdownToPayment = '00分00秒';
        if (diff === 0) this.req_detail({ orderNo: this.$route.query.orderNo });
      }

    },
    to_payment(detail) {
      const _this = this;
      Q.when()
        .then(function() {
          return _this.req_order_status({
            orderNo: detail.orderNo
          });
        })
        .then(function(res) {
          if (res.ifTimeout === 1) {
            _this.$router.push({
              name: 'distributor-train-front-pay',
              query: {
                orderNo: detail.orderNo,
                type: detail.orderType === 1 ? 'reserve' : 'booking'
              }
            });
          } else if (res.ifTimeout === 2) {
            _this.$message.warning('已超过支付时间，请重新下单！');
            _this.req_detail({
              orderNo: detail.orderNo
            });
          }
        });
    },
    to_search(type) {
      const now = moment().utcOffset(480).format('YYYY-MM-DD HH:mm:ss');
      const gmtStart = moment(this.detail.gmtStart).format('YYYY-MM-DD');
      let fromStationCode;
      let toStationCode;
      let time;
      if (type === 'again') {
        fromStationCode = this.detail.fromStationCode;
        toStationCode = this.detail.toStationCode;
        time =
          moment(gmtStart) <= moment(now) ?
          moment(now)
          .add(1, 'd')
          .format('YYYY-MM-DD') :
          gmtStart;
      } else if (type === 'back') {
        fromStationCode = this.detail.toStationCode;
        toStationCode = this.detail.fromStationCode;
        time =
          moment(gmtStart) <= moment(now) ?
          moment(now)
          .add(1, 'd')
          .format('YYYY-MM-DD') :
          moment(gmtStart)
          .add(1, 'd')
          .format('YYYY-MM-DD');
      }
      this.$router.push({
        name: 'distributor-train-front-search',
        query: {
          fromStationCode,
          toStationCode,
          time,
          onlySearch: 0
        }
      });
    },
    cancel_order(orderNo) {
      const _this = this;
      this.$confirm('您确认取消订单吗？（一天内最多取消3次）？', '取消订单', {
        confirmButtonText: '确认取消',
        cancelButtonText: '我再想想',
        type: 'warning'
      }).then(() => {
        _this.req_cancel_order({
          orderNo
        })
      });
    },
    alter_insurance (insurance) {
      this.insurance = insurance;
      this.insuranceVisble = true;
    },
    close_insurance () {
      this.insurance = {
        insuranceName: '',
        insuranceNo: '',
        insuranceStatus: '',
        insurancePrice: '',
        insuranceStatusText: '',
      };
      this.insuranceVisble = false;
    }
  },
  watch: {},
  mounted() {},
  activated() {
    document.documentElement.scrollTop = 0;
    this.req_detail({
      orderNo: this.$route.query.orderNo
    });
  },
  deactivated() {
    clearInterval(this.timer);
  }
};
